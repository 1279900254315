<template>
  <div>
    <!-- 교육교재 -->
    <c-card title="LBL0002809" height="600px">
      <template slot="card-detail">
        <div class="col-12">
          <!-- 교육계획 관련 파일 -->
          <c-upload 
            :attachInfo="attachEduPlanInfo"
            label="LBL0002899"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
      <template slot="card-detail">
        <div class="col-12">
          <!-- 교육과정 관련 파일 -->
          <c-upload 
            :attachInfo="attachEduCourseInfo"
            label="LBL0002900"
            :editable="false">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'education-material',
  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
    educationInfo: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
  },
  data() {
    return {
      attachEduPlanInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_PLAN',
        taskKey: '',
      },
      attachEduCourseInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_CURRICULUM',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    }
  },
  watch: {
    'param.eduEducationId'() {
      this.setTaskKey();
    },
    'educationInfo.eduCourseId'() {
      this.setTaskKey2();
    },
  },
  computed: {
    disabled() {
      return Boolean(this.educationInfo.documentStatusCd) && this.educationInfo.documentStatusCd !== 'ESC000001' && !this.param.planUpdateBtnData.flag
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.setTaskKey();
      this.setTaskKey2();
    },
    setTaskKey() {
      let taskKey = this.param.eduEducationId ? this.param.eduEducationId : '';
      this.attachEduPlanInfo.taskKey = taskKey
    },
    setTaskKey2() {
      let taskKey = this.educationInfo.eduCourseId ? this.educationInfo.eduCourseId : '';
      this.attachEduCourseInfo.taskKey = taskKey
    },
  },
}
</script>
